// @flow
import { VisuallyHidden } from "@reach/visually-hidden";
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import NoAbout from "core/components/Empty/NoAbout";
import Flex from "core/components/Flex";
import MarkdownPreviewHelper from "core/components/InputMarkdown/MarkdownPreviewHelper";
import InputRich from "core/components/InputRich";
import Markdown from "core/components/Markdown";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import Scrollable from "core/components/Scrollable";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import type { Project as TProject } from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  project: TProject,
  editButton?: React.Node,
|};

export type StateProps = {|
  canEdit?: boolean,
  isOnline: boolean,
|};

export type DispatchProps = {|
  updateAbout: (about: string) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  about: string,
  isEditing: boolean,
  showPreview: boolean,
};

class About extends React.Component<Props, State> {
  state = {
    about: "",
    isEditing: false,
    showPreview: false,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.keyCode === KeyCode.KEY_ESCAPE) {
      this.toggleEdit();
    }
  };

  handleDescriptionChange = (about: string) => {
    this.setState({ about });
  };

  handleSubmit = async (ev: SyntheticEvent<*>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { updateAbout } = this.props;
    const { about } = this.state;

    await updateAbout(about);
    this.toggleEdit();
  };

  handleEditClick = (ev: SyntheticMouseEvent<*>) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.toggleEdit(true);
  };

  toggleEdit = (isEditing: boolean = false) => {
    const { project } = this.props;

    this.setState({
      isEditing: isEditing === true,
      about: project.about ? project.about : "",
    });
  };

  renderPreview = () => {
    return (
      <Scrollable>
        <Markdown
          minHeight={120}
          maxHeight={320}
          text={this.state.about || ""}
        />
      </Scrollable>
    );
  };

  renderEditForm = () => {
    return (
      <React.Fragment>
        <InputRich
          projectId={this.props.project.id}
          minHeight={160}
          maxHeight={320}
          autoFocus
          focusClass={style.focused}
          placeholder=""
          onChange={this.handleDescriptionChange}
          value={this.state.about}
          maxLength={Validations.maxBranchDescriptionLength}
          onSubmit={this.handleSubmit}
        />
        <Flex justify="flex-end" align="center">
          <Button onClick={this.toggleEdit}>Cancel</Button>
          <Button
            onClick={this.handleSubmit}
            className={style.aboutFormButton}
            primary
          >
            Save Changes
          </Button>
        </Flex>
      </React.Fragment>
    );
  };

  renderDescription() {
    const { project, canEdit } = this.props;
    if (project.about) {
      return <Markdown text={project.about} />;
    }

    return <NoAbout canEdit={canEdit} className={style.noAbout} />;
  }

  render() {
    return (
      <OverviewCard
        headerControls={
          this.props.canEdit &&
          !this.props.mobile &&
          (this.state.isEditing ? (
            <MarkdownPreviewHelper
              preview={this.state.showPreview}
              onChange={(showPreview) => this.setState({ showPreview })}
            />
          ) : (
            <Button
              disabled={!this.props.isOnline}
              onClick={this.handleEditClick}
              title={
                this.props.isOnline
                  ? undefined
                  : "You can only edit the About section of a project while online"
              }
              tooltip={{ placement: "top-end" }}
            >
              Edit <VisuallyHidden>(disabled while offline)</VisuallyHidden>
            </Button>
          ))
        }
        heading="About"
        mobile={this.props.mobile}
        storageId="ProjectOverview-About"
        collapsed={this.state.isEditing ? false : undefined}
        disabled={this.state.isEditing}
        qaSelector="about-card"
        collapsible
      >
        <div className={classnames({ [style.about]: this.props.mobile })}>
          {this.state.isEditing
            ? this.state.showPreview
              ? this.renderPreview()
              : this.renderEditForm()
            : this.renderDescription()}
        </div>
      </OverviewCard>
    );
  }
}

export default connector(About);
