// @flow
import * as React from "react";
import { connect } from "react-redux";
import { loadOrganizationMemberships } from "core/actions/memberships";
import Button from "core/components/Button";
import Card from "core/components/Card";
import DialogForm from "core/components/DialogForm";
import Heading from "core/components/Heading";
import Input from "core/components/Input";
import OrganizationLogo from "core/components/OrganizationLogo";
import { LEAVE_ORGANIZATION } from "core/constants";
import { V3Link as Link } from "core/lib/router";
import { privacyUrl } from "core/lib/urls";
import { getMemberships } from "core/selectors/memberships";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";

import ContextMenu from "web/di/components/ContextMenu";
import { organizationPath } from "web/routeHelpers";
import style from "./style.scss";

type OwnProps = {|
  currentUserId: string,
  id: string,
  name: string,
  userId?: string,
  logoUrl?: string,
  isUsernameOrganization?: boolean,
  userIsScimProvisioned: boolean,
  leaveOrganization: (organizationId: string) => void,
  mobile: boolean,
|};

type StateProps = {|
  isCurrentUserOnlyAdmin: boolean,
  isCurrentUserOrganizationOwner: boolean,
  role: string,
|};

type DispatchProps = {|
  loadOrganizationMemberships: typeof loadOrganizationMemberships,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type ComponentState = {
  showConfirm: boolean,
  isSubmitting: boolean,
  confirmed: boolean,
};

class OrganizationListItem extends React.Component<Props, ComponentState> {
  state = { showConfirm: false, isSubmitting: false, confirmed: false };

  static getDerivedStateFromProps(props: Props) {
    if (!props.id) {
      return { showConfirm: false };
    }
    return null;
  }

  leaveOrganization = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.props.leaveOrganization(this.props.id);
  };

  showConfirm = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showConfirm: true, isSubmitting: false });
  };

  handleConfirm = ({ target }: SyntheticInputEvent<>) => {
    this.setState({
      confirmed: target.value === LEAVE_ORGANIZATION,
    });
  };

  hideConfirm = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showConfirm: false, confirmed: false });
  };

  loadMembersIfOrganizationOwner = () => {
    if (this.props.isCurrentUserOrganizationOwner) {
      this.props.loadOrganizationMemberships(this.props.id, { role: "owner" });
    }
  };

  renderConfirm = () => {
    if (!this.props.id) {
      return null;
    }

    return (
      <DialogForm
        dangerous
        onSubmit={this.leaveOrganization}
        isOpen={this.state.showConfirm}
        disabled={!this.state.confirmed || this.state.isSubmitting}
        onClose={this.hideConfirm}
        title="Leave this Organization"
        primaryButton={
          this.state.isSubmitting
            ? "Leaving this Organization…"
            : "Leave this Organization"
        }
        secondaryButton="Cancel"
        onSecondary={this.hideConfirm}
      >
        <div>
          <p>
            Once you leave <strong>{this.props.name}</strong> in Abstract, you
            will lose access to all of its projects, files, and other supporting
            content.{" "}
            <a href={privacyUrl()} target="_blank" rel="noopener noreferrer">
              {" "}
              Learn more about Abstract privacy and data policies...
            </a>
          </p>
          <p>
            To regain access after you leave, contact a {this.props.name} Admin.
          </p>
          <React.Fragment>
            <Input
              label={`Ready? Enter "${LEAVE_ORGANIZATION}" to proceed.`}
              onChange={this.handleConfirm}
              autoComplete="off"
              autoFocus
              placeholder="Confirm you want to leave this organization…"
              qaSelector="leaveOrganizationConfirmationInput"
            />
          </React.Fragment>
        </div>
      </DialogForm>
    );
  };

  menuItems = () => {
    return [
      {
        danger: true,
        enabled: !(
          (this.props.isCurrentUserOrganizationOwner &&
            !this.props.isCurrentUserOnlyAdmin) ||
          this.props.userIsScimProvisioned
        ),
        click: this.showConfirm,
        label: this.props.userIsScimProvisioned
          ? "Membership is managed by your administrator"
          : `Leave ${this.props.name}`,
      },
    ];
  };

  render() {
    return (
      <React.Fragment>
        <Card
          list={true}
          to={organizationPath(this.props.id)}
          component={Link}
          className={style.card}
          innerClassName={style.cardContents}
          mobile={this.props.mobile}
        >
          <OrganizationLogo
            size={40}
            alt={this.props.name}
            src={this.props.logoUrl}
          />
          <div className={style.wrap}>
            <div className={style.headingWrap}>
              <Heading level="3" size="m" className={style.heading}>
                {this.props.name}
              </Heading>
              <span className={style.role}>{`Role: ${this.props.role}`}</span>
            </div>
            <span className={style.underline}>Dashboard</span>
            <span className={style.buttonWrap}>
              <ContextMenu
                id={this.props.id}
                menuItems={this.menuItems()}
                onAfterShow={this.loadMembersIfOrganizationOwner}
              >
                {(showMenu, ref) => (
                  <Button
                    nude
                    icon="overflow"
                    onClick={showMenu}
                    innerRef={ref}
                  />
                )}
              </ContextMenu>
            </span>
          </div>
        </Card>
        {this.renderConfirm()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const policy = getOrganizationPolicy(state, { organizationId: props.id });
  let role = "Member";

  if (policy.manageSettings) {
    role = "Admin";
  } else if (!policy.listMembers) {
    role = "Guest";
  }

  const isCurrentUserOrganizationOwner = props.userId === props.currentUserId;
  const memberships = getMemberships(state, { organizationId: props.id });
  const isCurrentUserOnlyAdmin = memberships.some(
    (membership) =>
      membership.role === "owner" && membership.userId !== props.userId
  );

  return {
    isCurrentUserOnlyAdmin,
    isCurrentUserOrganizationOwner,
    role,
  };
}

const mapDispatchToProps = {
  loadOrganizationMemberships,
};

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationListItem);
