// @flow
import * as React from "react";
import Button from "core/components/Button";
import NotPermitted from "core/components/Empty/NotPermitted";
import ExternalLink from "core/components/ExternalLink";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import Note from "core/components/Note";
import OrganizationTeamHeader from "core/components/OrganizationTeam/OrganizationTeamHeader";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import TeamColorSelector from "core/components/TeamColorSelector";
import { helpCenterUrl } from "core/lib/urls";
import type {
  Team,
  Policy,
  OrganizationTeamDescriptor,
  ReactRouterLocation,
  TeamUpdateParams,
} from "core/types";
import Validations from "core/validations";
import ConfirmDeleteTeam from "./ConfirmDeleteTeam";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: OrganizationTeamDescriptor,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  team: Team,
  policy: Policy,
  teamMembershipCount: ?number,
  updateAllowed: boolean,
  destroyAllowed: boolean,
|};

export type DispatchProps = {|
  onTeamUpdate: (params: TeamUpdateParams) => void,
  onTeamDelete: (params: OrganizationTeamDescriptor) => void,
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function OrganizationTeamSettings(props: Props) {
  const [teamName, setTeamName] = React.useState(props.team.name);
  const [teamColor, setTeamColor] = React.useState(props.team.color);
  const [isDialogOpened, setIsDialogOpened] = React.useState(false);

  const handleOpen = () => {
    setIsDialogOpened(true);
  };

  const handleClose = () => {
    setIsDialogOpened(false);
  };

  const handleSubmit = () => {
    props.onTeamDelete({
      organizationId: props.params.organizationId,
      teamId: props.team.id,
    });
    handleClose();
  };
  const onSubmit = function (event: SyntheticInputEvent<>) {
    event.preventDefault();
    props.onTeamUpdate({
      organizationId: props.params.organizationId,
      teamId: props.team.id,
      name: teamName,
      color: teamColor,
    });
  };

  if (!props.updateAllowed && !props.destroyAllowed) {
    return (
      <MainContent>
        <NotPermitted />
      </MainContent>
    );
  }

  return (
    <Media desktop>
      {(desktop) => (
        <React.Fragment>
          <OrganizationTeamHeader
            params={props.params}
            title={props.team.name}
            desktop={desktop}
            query={props.location.query}
            policy={props.policy}
            pathname={props.location.pathname}
          />
          <SettingsForm onSubmit={onSubmit}>
            <FormSection heading="Details">
              <SettingsItem input>
                <Input
                  required
                  requiredTag
                  responsive
                  label="Name"
                  name="Name"
                  onChange={(event) => {
                    setTeamName(event.target.value);
                  }}
                  defaultValue={props.team.name}
                  minLength={Validations.minTeamNameLength}
                  maxLength={Validations.maxTeamNameLength}
                />
              </SettingsItem>
              <SettingsItem responsive label="Color">
                <TeamColorSelector
                  teamName={teamName}
                  selectedColor={teamColor}
                  onChange={(color: string) => {
                    setTeamColor(color);
                  }}
                  desktop={desktop}
                  showWrapper={false}
                />
              </SettingsItem>
              <SettingsItem className={style.submit}>
                <Button primary type="submit">
                  Save changes
                </Button>
              </SettingsItem>
            </FormSection>
            {props.destroyAllowed && (
              <FormSection heading="Delete Team">
                <SettingsItem>
                  <Note>
                    Once you delete a team, its organization members will only
                    lose access to this team's private projects. Guest access to
                    the organization will be revoked, unless they are part of
                    another team or project.{" "}
                    <ExternalLink href={helpCenterUrl()}>
                      Learn more…
                    </ExternalLink>
                  </Note>
                </SettingsItem>
                <SettingsItem className={style.submit}>
                  <Button onClick={handleOpen} danger={true}>
                    Delete Team
                  </Button>
                </SettingsItem>
              </FormSection>
            )}
          </SettingsForm>
          <ConfirmDeleteTeam
            isOpen={isDialogOpened}
            onClose={handleClose}
            onSubmit={handleSubmit}
            team={props.team}
            teamMembershipCount={props.teamMembershipCount}
          />
        </React.Fragment>
      )}
    </Media>
  );
}

export default connector(OrganizationTeamSettings);
