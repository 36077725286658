// @flow
import pluralize from "pluralize";
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import TeamAvatar from "core/components/TeamAvatar";
import { helpCenterUrl } from "core/lib/urls";
import type { Team } from "core/types";
import style from "./style.scss";

type Props = {
  team: Team,
  teamMembershipCount: ?number,
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void,
};

export default function ConfirmDeleteTeam(props: Props) {
  return (
    <DialogForm
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      title="Delete this team"
      primaryButton="Delete this team"
      dangerous
    >
      <div className={style.teamInfoWrapper}>
        <TeamAvatar
          teamColor={props.team.color}
          teamName={props.team.name}
          size={36}
          className={style.avatar}
          showBorder={false}
        />
        <div className={style.teamInfo}>
          <span className={style.teamName}>{props.team.name}</span>
          <span>
            {pluralize("member", props.teamMembershipCount || 0, true)}
          </span>
        </div>
      </div>
      <p>
        Once you delete a team, its organization members will only lose access
        to this team's private projects. Guest access to the organization will
        be revoked, unless they are part of another team.{" "}
        <ExternalLink href={helpCenterUrl()}>Learn more…</ExternalLink>
      </p>
    </DialogForm>
  );
}
