// @flow
import empty from "empty";
import AbstractConfig from "abstract-di/config";
import type {
  LayerOptions,
  OrganizationRole,
  SubscriptionRole,
} from "core/types";
import { query } from "./routes";

const APP_DOMAIN = "goabstract.com";

let webAppURL = `https://app.${APP_DOMAIN}`;
AbstractConfig.getAppURL().then((url) => (webAppURL = url));

export function homeUrl() {
  return `https://www.${APP_DOMAIN}`;
}

export function webAppUrl() {
  return webAppURL;
}

export function blogUrl() {
  return `${homeUrl()}/blog/`;
}

export function statusUrl() {
  return "https://status.goabstract.com";
}

export function releaseNotesUrl(version: ?string) {
  return `${homeUrl()}/release-notes/${version ? `#version-${version}` : ""}`;
}

export function facebookCommunityUrl() {
  return "https://www.facebook.com/groups/893366660785360/";
}

export function helpCenterUrl() {
  return `https://help.${APP_DOMAIN}`;
}

export function helpArchiveProjectUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049923052-Archive-or-delete-a-project`;
}

export function helpAssetsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380071-Assets`;
}

export function helpBranchesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049923152-Creating-Branches`;
}

export function helpUpdateBillingUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050381091-Change-your-subscription`;
}

export function helpCollectionsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925552-Collections`;
}

export function helpCommentsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925612-Comments-and-annotations`;
}

export function helpImportingFilesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050377751-Creating-Projects`;
}

export function helpIncompatibleFiles() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049923072-Add-files-to-your-project`;
}

export function helpGuestsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380331-Send-an-invitation`;
}

export function helpManagingTeamsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380311-Organization-Overview`;
}

export function helpPricingUrl() {
  return `${homeUrl()}/pricing/`;
}

export function helpProjectsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050377751-Creating-Projects`;
}

export function helpProjectPermissionsGuestUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050377731-Project-permissions#guests`;
}

export function helpProjectPermissionsAccessRequestUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050377731-Project-permissions`;
}

export function helpSeatAndRolesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925792-Understand-seat-and-role-types`;
}

export function helpSyncUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050377711-Trouble-with-projects-and-syncing`;
}

export function helpRolesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380371-Manage-user-permissions-and-access`;
}

export function helpSamlSingleSignOn() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380351-Configure-single-sign-on-SSO-using-Google-SAML`;
}

export function helpScimProvisioning() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049926012-SCIM-provisioning`;
}

export function helpSharingUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380031-Share-your-designs`;
}

export function helpShareSettingsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380311-Organization-Overview`;
}

export function helpTeamsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049922952-Teams`;
}

export function helpTransferProjectUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049922972-Transfer-a-project`;
}

export function helpLibrariesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049926832-Libraries`;
}

export function helpLinkLibrariesUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050379891-Linking-Libraries`;
}

export function helpBrokenSymbolsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925432-Managing-Libraries`;
}

export function helpJoinOrganizationUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380431-Join-an-Organization#i-cant-find-my-companys-existing-abstract-organization`;
}

export function helpMergeRestrictionsUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050380451-Merge-restrictions`;
}

export function helpFileSize() {
  // TODO: Update with actual URL once article is written
  return `${helpCenterUrl()}/hc/en-us/categories/360004353732-Best-Practices`;
}

export function privacyUrl() {
  return `${homeUrl()}/legal/privacy-policy/`;
}

export function supportTicketUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050382291-Contact-Support`;
}

export function termsOfServiceUrl() {
  return `${legalUrl()}/customer-terms-of-service/`;
}

export function freePlanAgreementUrl() {
  return `${legalUrl()}/free-plan-agreement/`;
}

export function trialAgreementUrl() {
  return `${legalUrl()}/trial-agreement/`;
}

export function learnMoreAboutSeats() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050381071-Manage-billing`;
}

export function learnMoreAboutReviews() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925592-Request-a-design-review`;
}

export function learnAbstractDesignWorkflow() {
  return `${helpCenterUrl()}/hc/en-us/categories/360004383431-Getting-Started`;
}

export function learnAbstractTestDrive() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049919232-Test-drive-Abstract`;
}

export function enterpriseScheduleDemoUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360050382291-Contact-Support#make-a-sales-inquiry`;
}

export function layerUrl(
  projectId: string,
  branchId: string,
  commitSha: string,
  fileId: string,
  layerId: string,
  options: LayerOptions = empty.object
) {
  return `${webAppURL}/projects/${projectId}/branches/${branchId}/commits/${commitSha}/files/${fileId}/layers/${layerId}${query(
    options
  )}`;
}

export function commitUrl(
  projectId: string,
  branchId: string,
  commitSha: string
) {
  return `${webAppURL}/projects/${projectId}/branches/${branchId}/commits/${commitSha}`;
}

export function layerShareUrl(
  shareId: string,
  options: LayerOptions = empty.object
) {
  return `${webAppURL}/share/${shareId}${query(options)}`;
}

export function shareUrl(shareId: string) {
  return `https://share.${APP_DOMAIN}/${shareId}`;
}

export function organizationSettingsUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/edit`;
}

export function organizationDetailsUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/edit/details`;
}

export function organizationPermissionsUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/edit/permissions`;
}

export function organizationIntegrationsUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/edit/integrations`;
}

export function organizationSharingUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/edit/sharing`;
}

export function organizationBillingUrl(
  organizationId: string,
  queries?: { [string]: string }
) {
  return `${webAppURL}/organizations/${organizationId}/edit/subscription${query(
    queries
  )}`;
}

export function organizationPeopleUrl(
  organizationId: string,
  role?: OrganizationRole | SubscriptionRole
) {
  return `${webAppURL}/organizations/${organizationId}/people${
    role ? `?role=${role}` : ""
  }`;
}

export function organizationEmojisUrl(organizationId: string): string {
  return `${webAppURL}/organizations/${organizationId}/emojis`;
}

export function organizationAdminsUrl(organizationId: string) {
  return `${organizationPeopleUrl(organizationId)}?role=admin`;
}

export function organizationReportingUrl(organizationId: string) {
  return `${webAppURL}/organizations/${organizationId}/reporting`;
}

export function projectUrl(projectId: string) {
  return `${webAppURL}/projects/${projectId}`;
}

export function collectionUrl(
  projectId: string,
  branchId: string,
  collectionId: string
) {
  return `${webAppURL}/projects/${projectId}/branches/${branchId}/collections/${collectionId}`;
}

export function helpWebhooksUrl() {
  return `https://developer.${APP_DOMAIN}/docs/webhooks/`;
}

export function latestReleaseDownloadUrl() {
  return "https://api.goabstract.com/releases/latest/download";
}

export function deleteOrganizationUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049925992-Delete-an-Organization`;
}

export function exportProjectUrl() {
  return `${helpCenterUrl()}/hc/en-us/articles/360049922992-Export-files-or-projects`;
}

export function legalUrl() {
  return `${homeUrl()}/legal`;
}

export function acceptableUsePolicyUrl() {
  return `${legalUrl()}/acceptable-use-policy`;
}

export function enterpriseTermsOfServiceUrl() {
  return `${legalUrl()}/enterprise-terms-of-service`;
}

export function figmaPluginUrl() {
  return "https://www.figma.com/community/plugin/900484667691435460/Abstract";
}

export function figmaFileUrl(fileKey: string, nodeId?: string) {
  // Expected format: https://www.figma.com/file/:key/:title?node-id=0
  // If :title is not supplied, Figma will load the title on its own.
  let url = `https://figma.com/file/${fileKey}`;
  if (nodeId) {
    url += `?node-id=${nodeId}`;
  }
  return url;
}

export function startBranchesTrialUrl() {
  return `${webAppURL}/start-branches-trial`;
}
