// @flow
/* global */
import * as Abstract from "abstract-sdk";
import currency from "currency.js";
import format from "date-fns/format";
import invariant from "invariant";
import capitalize from "lodash/capitalize";
import pluralize from "pluralize";
import React, { Component } from "react";
import apiRequest from "abstract-di/api";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import billingModalStyle from "core/components/CreateOrganization/style.scss";
import SalesRequested from "core/components/Dialog/SalesRequested";
import DialogForm from "core/components/DialogForm";
import Error from "core/components/Empty/Error";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import FormNotice from "core/components/FormNotice";
import FormSection from "core/components/FormSection";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import Icon from "core/components/Icon";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import Note from "core/components/Note";
import Popover from "core/components/Popover";
import PriceCalculation from "core/components/PriceCalculation";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import Theme from "core/components/Theme";
import { ENTERPRISE_EMAIL } from "core/constants";
import { fromNow } from "core/lib/dates";
import { V3Link as Link } from "core/lib/router";
import {
  NON_ENTERPRISE_SEAT_CAP,
  availableSeats as getAvailableSeats,
  availableAtRenewalSeats as getAvailableAtRenewalSeats,
  totalPrice,
  prices,
  subscriptionTypeToDisplayName,
} from "core/lib/subscriptions";
import type { Organization, ReactRouterLocation } from "core/types";
import {
  updateSubscription,
  reactivateSubscription,
  fetchInvoices,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  UnexpectedError,
} from "web/api";
import { recurlyAccountManagement } from "web/api/urls";
import BillingForm from "web/components/BillingForm";
import { organizationPeople } from "web/routeHelpers";
import type { Subscription, Invoice } from "web/types";
import AddSeats from "./AddSeats";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import ChoosePlan from "./ChoosePlan";
import CompanyDetailsForm from "./CompanyDetailsForm";
import InvoiceItem from "./InvoiceItem";
import PaymentDetails from "./PaymentDetails";
import RemoveSeats from "./RemoveSeats";
import connector from "./connector";
import style from "./style.scss";

const formatDate = (date: string) => {
  return format(date, "MMM Do, YYYY");
};

const dialogStrings: { [string]: string } = {
  addPaymentDetails: "addPaymentDetails",
  updatePaymentDetails: "updatePaymentDetails",
  updateCompanyDetails: "updateCompanyDetails",
  changePlan: "changePlan",
  addSeats: "addSeats",
  removeSeats: "removeSeats",
  confirmCancel: "confirmCancel",
};

type TDialog = $Keys<typeof dialogStrings>;

export type OwnProps = {|
  location: ReactRouterLocation,
  params: Abstract.OrganizationDescriptor,
  seatsLoading: boolean,
|};

export type StateProps = {|
  contractType: $PropertyType<Organization, "contractType">,
  isUsernameOrganization: boolean,
  organizationId: string,
  seatCapEnabled: boolean,
  organization: ?Organization,
  subscription: ?Subscription,
  subscriptionError: boolean,
  showNextBill: boolean,
  areCancelAndDeleteButtonsVisible: boolean,
|};

export type DispatchProps = {|
  loginRequired: () => void,
  resetRecurly: () => void,
  loadOrganization: (organizationId: string) => void,
  onLoadSubscription: () => void,
  openSupportTicket: () => void,
  trackNewSubscription: ({
    planName?: string,
    annual?: boolean,
    planBillingAmount?: string,
    planStartDate: string,
  }) => void,
  trackSubscriptionReactivated: () => void,
  resetSeatsChanges: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  dialog?: TDialog,
  planName: string,
  planAnnual: boolean,
  invoices: Invoice[],
  isFetching: boolean,
  error: string,
  isSalesRequestedDialogOpen: boolean,
};

class OrganizationSettingsSubscription extends Component<Props, State> {
  state = {
    dialog: undefined,
    planName: "",
    planAnnual: false,
    invoices: [],
    isFetching: false,
    error: "",
    isSalesRequestedDialogOpen: false,
  };

  componentDidMount() {
    this.props.onLoadSubscription();
    this.loadInvoices(this.props.params.organizationId);
  }

  loadInvoices = async (organizationId: string) => {
    let invoices;

    try {
      invoices = await fetchInvoices(organizationId);
    } catch (err) {
      switch (err.constructor) {
        case UnauthorizedError:
          return this.props.loginRequired();
        case ForbiddenError:
          return this.setState({ error: "forbidden" });
        case NotFoundError:
          return this.setState({ error: "not found" });
        case UnexpectedError:
          return this.setState({ error: "unexpected" });
        default:
          throw err;
      }
    }

    this.setState({ invoices });
  };

  handleAutomaticInvoicing = async (event: SyntheticInputEvent<>) => {
    this.setState({ isFetching: true });

    try {
      await updateSubscription(this.props.params.organizationId, {
        collectionMethod: "automatic",
      });
    } catch (err) {
      switch (err.constructor) {
        case UnauthorizedError:
          return this.props.loginRequired();
        case ForbiddenError:
          return this.setState({ error: "forbidden" });
        case NotFoundError:
          return this.setState({ error: "not found" });
        case UnexpectedError:
          return this.setState({ error: "unexpected" });
        default:
          throw err;
      }
    } finally {
      this.setState({ isFetching: false });
    }

    this.props.onLoadSubscription();
  };

  handleReactivateSubscription = async () => {
    this.setState({ isFetching: true });

    try {
      await reactivateSubscription(this.props.params.organizationId, {
        onSuccess: this.props.trackSubscriptionReactivated,
      });
    } catch (err) {
      switch (err.constructor) {
        case UnauthorizedError:
          return this.props.loginRequired();
        case ForbiddenError:
          return this.setState({ error: "forbidden" });
        case NotFoundError:
          return this.setState({ error: "not found" });
        case UnexpectedError:
          return this.setState({ error: "unexpected" });
        default:
          throw err;
      }
    } finally {
      this.setState({ isFetching: false });
    }

    this.props.onLoadSubscription();
  };

  showChangePlan = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "changePlan" });
  };

  showUpdatePaymentDetails = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "updatePaymentDetails" });
  };

  showUpdateCompanyDetails = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "updateCompanyDetails" });
  };

  showCreateSubscription = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "addPaymentDetails" });
  };

  showAddSeats = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "addSeats" });
  };

  showRemoveSeats = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "removeSeats" });
  };

  showCancelDowgrade = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "cancelDowngrade" });
  };

  showConfirmCancelSubscription = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: "confirmCancel" });
  };

  hideDialog = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialog: undefined });
    this.props.resetRecurly();
  };

  toggleSalesRequestedDialog = (isOpen: boolean) => {
    this.setState({ isSalesRequestedDialogOpen: isOpen });
  };

  resetPendingDowngrade = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.hideDialog();

    const { subscription, resetSeatsChanges } = this.props;

    if (
      subscription &&
      !!subscription.totalSeatsPending &&
      subscription.totalSeats > subscription.totalSeatsPending
    ) {
      resetSeatsChanges();
    }
  };

  handleNewSubscription = async (subscription: Subscription) => {
    this.hideDialog();
    await this.props.loadOrganization(this.props.params.organizationId);
    this.props.onLoadSubscription();

    const period = this.state.planAnnual ? "Annual" : "Monthly";
    const price = prices[`${this.state.planName}${period}Price`] / 100;

    const total = totalPrice({
      price,
      annual: this.state.planAnnual,
      quantity: subscription.quantity,
    });

    this.props.trackNewSubscription({
      planName: this.state.planName,
      annual: this.state.planAnnual,
      planBillingAmount: total,
      planStartDate: new Date().toISOString(),
    });
  };

  handleSubscriptionUpdated = async (subscription: Subscription) => {
    this.hideDialog();
    await this.props.loadOrganization(this.props.params.organizationId);
    this.props.onLoadSubscription();
  };

  handleDismiss = () => {
    this.setState({ dialog: undefined });
    this.props.onLoadSubscription();
  };

  subscriptionName = (subscription: Subscription) => {
    let name = subscriptionTypeToDisplayName.get(subscription.type);
    invariant(name, "Subscription name must exist");

    if (subscription.type === "enterprise") {
      const interval = subscription.annual ? "Year" : "Month";
      const termLength =
        subscription.termLength >= 12
          ? subscription.termLength / 12
          : subscription.termLength;
      // Enterprise (1 year)
      // Enterprise (N Years)
      name += ` (${termLength} ${pluralize(interval, termLength)})`;
    } else {
      name += subscription.annual ? " Annual" : " Monthly";
    }

    if (subscription.status === "canceled") {
      name += " (Canceled)";
    }
    if (subscription.inTrial) {
      name += " (Trial)";
    }

    return name;
  };

  getRemoveSeatsTitle = (subscription: Subscription) => {
    if (subscription.totalSeats === subscription.minSeats) {
      return "You have reached the contractual minimum amount of seats.";
    }

    const totalSeats =
      subscription.totalSeatsPending || subscription.totalSeats;
    const seatsAvailable = totalSeats - subscription.usedSeats > 0;

    if (seatsAvailable) {
      return "Remove seats";
    } else {
      return "You can’t remove seats before choosing which seats to make availible for removal. Convert Contributors to Viewers or remove them from Abstract, to have availible seats to remove.";
    }
  };

  getAddSeatsTitle = (
    subscription: Subscription,
    scheduledSeatsRemoval: boolean
  ) => {
    if (scheduledSeatsRemoval) {
      return "You need to cancel your pending subscription downgrade before you can add more seats.";
    }

    if (getAvailableSeats(subscription)) {
      return "You can’t add new seats while you have availible seats at your disposal. Convert Viewers to Contributors or invite new contributors to Abstract.";
    }

    return subscription.totalSeats === subscription.maxSeats
      ? "Contact Sales to add additional seats."
      : "Add seats";
  };

  renderCreateSubscription() {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }

    const period = this.state.planAnnual ? "Annual" : "Monthly";
    const price = prices[`${this.state.planName}${period}Price`] / 100;
    const currentPeriodEndsAt: ?string = subscription.currentPeriodEndsAt;

    return (
      <DialogForm
        isOpen={this.state.dialog === "addPaymentDetails"}
        onRequestClose={this.hideDialog}
        onClose={this.hideDialog}
        title="Enter a payment method"
        alternativePadding
        headingClassName={billingModalStyle.billingModalHeading}
        className={billingModalStyle.fullHeightModal}
        primaryButton=""
      >
        <BillingForm
          planName={this.state.planName}
          planAnnual={this.state.planAnnual}
          organizationId={this.props.organizationId}
          onComplete={this.handleNewSubscription}
          submitText="Start Subscription"
        >
          <PriceCalculation
            className={billingModalStyle.priceCalculation}
            description={
              currentPeriodEndsAt ? (
                <span>
                  First payment on{" "}
                  <time>{formatDate(currentPeriodEndsAt)}</time>
                </span>
              ) : (
                <span>Billed {period}</span>
              )
            }
            totalPrice={totalPrice({
              price,
              annual: this.state.planAnnual,
              quantity: subscription.quantity,
            })}
            monthlyPrice={price}
            annual={this.state.planAnnual}
            quantity={subscription.quantity}
          />
        </BillingForm>
      </DialogForm>
    );
  }

  renderUpdatePaymentDetails() {
    return (
      <DialogForm
        isOpen={this.state.dialog === "updatePaymentDetails"}
        onRequestClose={this.hideDialog}
        onClose={this.hideDialog}
        title="Update payment method"
        alternativePadding
        primaryButton=""
      >
        <BillingForm
          organizationId={this.props.organizationId}
          onComplete={this.handleSubscriptionUpdated}
          submitText="Save Changes"
        />
      </DialogForm>
    );
  }

  renderAddSeats() {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }

    return (
      <AddSeats
        isOpen={this.state.dialog === "addSeats"}
        onClose={this.hideDialog}
        title="Add contributor seats"
        submitText="Add 1 Seat"
        subscription={subscription}
        organizationId={this.props.organizationId}
        onDismiss={this.handleDismiss}
        isLoading={this.props.seatsLoading}
      />
    );
  }

  renderRemoveSeats() {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }

    return (
      <RemoveSeats
        isOpen={this.state.dialog === "removeSeats"}
        onClose={this.hideDialog}
        title="Remove contributor seats"
        submitText="Remove 1 Seat"
        subscription={subscription}
        organizationId={this.props.organizationId}
        onDismiss={this.handleDismiss}
        isLoading={this.props.seatsLoading}
      />
    );
  }

  renderUpdateCompanyDetails() {
    return (
      <DialogForm
        isOpen={this.state.dialog === "updateCompanyDetails"}
        onRequestClose={this.hideDialog}
        onClose={this.hideDialog}
        title="Edit company details"
        alternativePadding
        primaryButton=""
      >
        <CompanyDetailsForm
          subscription={this.props.subscription}
          organizationId={this.props.organizationId}
          onComplete={this.handleSubscriptionUpdated}
          submitText="Save Changes"
        />
      </DialogForm>
    );
  }

  renderChangePlan() {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }
    const hasPaymentDetails = !!subscription.cardType;

    return (
      <DialogForm
        size="fullScreen"
        className={style.changePlanDialog}
        isOpen={this.state.dialog === "changePlan"}
        onRequestClose={this.hideDialog}
        onClose={this.hideDialog}
        contentClassName={style.changePlanDialog}
        title="Update Plan"
        primaryButton=""
      >
        <ChoosePlan
          annual={subscription.annual}
          contributors={subscription.quantity}
          currentPlan={subscription.type}
          trialEndsAt={subscription.trialEndsAt}
          inTrial={subscription.inTrial}
          hasPaymentDetails={hasPaymentDetails}
          onSubmit={this.handleSelectPlan}
          organizationId={this.props.organizationId}
        />
      </DialogForm>
    );
  }

  handleSelectPlan = (name: string, annual: boolean) => {
    this.setState({ planName: name, planAnnual: annual });
    this.showCreateSubscription();
  };

  handleSalesRequest = async () => {
    try {
      await apiRequest("put", "request_sales_contact");
      this.toggleSalesRequestedDialog(true);
    } catch (error) {
      /**TODO potential error states for unsuccessful request */
    }
  };

  renderSeatNote(subscription: Subscription) {
    const { maxSeats, minSeats } = subscription;

    // there's nothing to render if no seat limits are set
    if (!(maxSeats || minSeats)) {
      return null;
    }

    const contactLink =
      subscription.type === "enterprise" ? (
        <a href={`mailto:${ENTERPRISE_EMAIL}`}>contact your account manager</a>
      ) : (
        "contact your account manager"
      );

    return (
      <Note className={style.seatNote}>
        Your current contract specifies
        {subscription.maxSeats &&
          ` a maximum seat count of ${subscription.maxSeats}`}
        {subscription.maxSeats && subscription.minSeats && " and"}
        {subscription.minSeats &&
          ` a minimum seat count of ${subscription.minSeats}`}
        . Please {contactLink} if you wish to change these parameters or if you
        need further assistance.
      </Note>
    );
  }

  renderPlanHeading = () => {
    const { subscription } = this.props;
    if (!subscription) {
      return "";
    }
    switch (subscription.type) {
      case "free":
      case "starter":
      case "business":
      case "enterprise": {
        return (
          <Flex justify="space-between" fullWidth>
            <span>
              You are currently using{" "}
              {subscriptionTypeToDisplayName.get(subscription.type)}
            </span>
          </Flex>
        );
      }

      default: {
        const planName = subscriptionTypeToDisplayName.get(subscription.type);
        if (planName) {
          return `You are currently using ${planName}`;
        }
        return "Your Plan";
      }
    }
  };

  render() {
    const {
      subscriptionError,
      isUsernameOrganization,
      organizationId,
      subscription,
      seatCapEnabled,
      showNextBill,
    } = this.props;

    const { error, invoices, isFetching } = this.state;

    const header = (
      <Media desktop>
        {(desktop) => <FormHeader mobile={!desktop} icon="dollar" />}
      </Media>
    );

    if (error || !subscription || !invoices) {
      return (
        <SettingsForm header={header}>
          {error ? (
            <Error flex />
          ) : (
            <Loaded loading flex title="Loading subscription…" />
          )}
        </SettingsForm>
      );
    }

    const hasPaymentDetails = !!subscription.cardType;
    const isCanceled = subscription.status === "canceled";
    const isActive = subscription.status === "active";
    const isBranchesPlan =
      this.props.organization && this.props.organization.versionsEnabled;
    const isPaidBranchesPlan = isBranchesPlan && subscription.type !== "free";
    const isPremium = subscription.type !== "free";
    const manualCollection = subscription.collectionMethod === "manual";
    const showChoosePlanScreen =
      !isActive &&
      isBranchesPlan &&
      !isPaidBranchesPlan &&
      !isUsernameOrganization &&
      !manualCollection;
    const currentPeriodEndsAt: ?string = subscription.currentPeriodEndsAt;
    const availableAtRenewalSeats = getAvailableAtRenewalSeats(subscription);
    const hasUnlimitedSeats = subscription.maxSeats == null;
    const showNonEnterpriseSeatCapNote =
      seatCapEnabled &&
      subscription.type !== "enterprise" &&
      subscription.maxSeats === NON_ENTERPRISE_SEAT_CAP;
    const scheduledSeatsRemoval: boolean =
      !!subscription.totalSeatsPending &&
      subscription.totalSeats > subscription.totalSeatsPending;
    const pendingRemovalSeats = scheduledSeatsRemoval
      ? subscription.totalSeats - subscription.totalSeatsPending
      : 0;

    return (
      <SettingsForm header={header}>
        <CancelSubscriptionDialog
          isOpen={this.state.dialog === "confirmCancel"}
          onClose={this.hideDialog}
          organizationId={organizationId}
        />
        {this.renderChangePlan()}
        {this.renderCreateSubscription()}
        {this.renderUpdatePaymentDetails()}
        {this.renderUpdateCompanyDetails()}
        {this.renderAddSeats()}
        {this.renderRemoveSeats()}
        {showChoosePlanScreen ? (
          <React.Fragment>
            <ChoosePlan
              annual={subscription.annual}
              contributors={subscription.quantity}
              currentPlan={subscription.type}
              trialEndsAt={subscription.trialEndsAt}
              inTrial={subscription.inTrial}
              onSubmit={this.handleSelectPlan}
              organizationId={organizationId}
              hasPaymentDetails={hasPaymentDetails}
            />
            {!!invoices.length && (
              <FormSection heading="Billing History">
                {invoices.map((invoice) => (
                  <InvoiceItem key={invoice.createdAt} invoice={invoice} />
                ))}
              </FormSection>
            )}
          </React.Fragment>
        ) : (
          <div>
            {this.props.organization && this.props.subscription && (
              <div>
                <FormSection
                  heading={this.renderPlanHeading()}
                  headingSize="xl"
                >
                  <SettingsItem label="Plan" responsive>
                    <span>{this.subscriptionName(subscription)}</span>
                    {isUsernameOrganization ||
                    "enterprise" === subscription.type ? undefined : (
                      <span>
                        {isCanceled ? (
                          <Button
                            onClick={this.handleReactivateSubscription}
                            disabled={isFetching}
                            primary
                          >
                            Reactivate subscription
                          </Button>
                        ) : (
                          <Button
                            onClick={this.showChangePlan}
                            primary
                            disabled={scheduledSeatsRemoval}
                            tooltip={scheduledSeatsRemoval}
                            title={
                              scheduledSeatsRemoval
                                ? "You need to cancel your pending subscription downgrade before you can make changes to your plan."
                                : subscription.inTrial
                                ? "Update Plan…"
                                : "Change Plan…"
                            }
                          >
                            {subscription.inTrial
                              ? "Update Plan…"
                              : "Change Plan…"}
                          </Button>
                        )}
                      </span>
                    )}
                  </SettingsItem>
                  {subscription.type !== "free" && (
                    <div>
                      <SettingsItem label="Contributor seats" responsive>
                        <Flex column grow>
                          {showNonEnterpriseSeatCapNote && (
                            <div>
                              {subscription.usedSeats >=
                                NON_ENTERPRISE_SEAT_CAP && (
                                <span className={style.seatCapReached}>
                                  <span className={style.seatCapIcon}>
                                    <Icon type="info" fill="currentColor" />
                                  </span>
                                  <strong>
                                    You are using the maximum number of seats.
                                  </strong>
                                  <br />
                                </span>
                              )}
                              {capitalize(subscription.type)} plans support{" "}
                              <strong>up to {NON_ENTERPRISE_SEAT_CAP}</strong>{" "}
                              Contributors. To add additional seats,{" "}
                              <Link
                                // Currently, plan downgrades aren't supported for self-service
                                className={style.salesLink}
                                component={Link}
                                rel="noopener noreferrer"
                                target="_blank"
                                to="/contact-support"
                              >
                                contact Support…
                              </Link>
                              <HorizontalSeparator />
                            </div>
                          )}
                          {!subscription.inTrial && !isCanceled && (
                            <div>
                              <Flex justify="space-between">
                                <span>
                                  <strong>{subscription.totalSeats}</strong>{" "}
                                  {pluralize("seats", subscription.totalSeats)}
                                </span>
                                {["enterprise", "pro"].includes(
                                  subscription.type
                                ) &&
                                  hasUnlimitedSeats && (
                                    <span className={style.seatButtons}>
                                      <Button
                                        onClick={this.showRemoveSeats}
                                        disabled={
                                          subscriptionError ||
                                          !availableAtRenewalSeats ||
                                          subscription.totalSeats ===
                                            subscription.minSeats
                                        }
                                        tooltip={
                                          !availableAtRenewalSeats ||
                                          subscription.totalSeats ===
                                            subscription.minSeats
                                        }
                                        title={this.getRemoveSeatsTitle(
                                          subscription
                                        )}
                                      >
                                        Remove Seats…
                                      </Button>

                                      <Button
                                        onClick={this.showAddSeats}
                                        disabled={
                                          scheduledSeatsRemoval ||
                                          availableAtRenewalSeats > 0 ||
                                          (!hasUnlimitedSeats &&
                                            (subscriptionError ||
                                              subscription.totalSeats ===
                                                subscription.maxSeats))
                                        }
                                        tooltip={
                                          scheduledSeatsRemoval ||
                                          availableAtRenewalSeats > 0 ||
                                          (!hasUnlimitedSeats &&
                                            subscription.totalSeats ===
                                              subscription.maxSeats)
                                        }
                                        title={this.getAddSeatsTitle(
                                          subscription,
                                          scheduledSeatsRemoval
                                        )}
                                      >
                                        Add Seats…
                                      </Button>
                                    </span>
                                  )}
                              </Flex>
                              <HorizontalSeparator />
                            </div>
                          )}
                          <Flex justify="space-between">
                            <div>
                              <strong>{subscription.usedSeats}</strong> used
                              {!subscription.inTrial && (
                                <span>
                                  ,<strong> {availableAtRenewalSeats}</strong>{" "}
                                  available
                                </span>
                              )}
                              {scheduledSeatsRemoval && ","}
                            </div>
                            <Link
                              to={organizationPeople(
                                organizationId,
                                "contributor"
                              )}
                            >
                              Manage contributors
                            </Link>
                          </Flex>
                          {scheduledSeatsRemoval && (
                            <div>
                              <strong>{pendingRemovalSeats}</strong> pending
                              removal
                            </div>
                          )}
                          {!showNonEnterpriseSeatCapNote &&
                            this.renderSeatNote(subscription)}
                          {subscriptionError && (
                            <p className={style.error}>
                              We currently can’t make any changes to your
                              subscription due to API issues with our billing
                              provider, please try again later.
                            </p>
                          )}
                        </Flex>
                      </SettingsItem>
                      <SettingsItem
                        responsive
                        label="Price per seat"
                        className={style.priceItem}
                      >
                        <span>
                          <strong>
                            {currency(subscription.priceInCents)
                              .divide(100)
                              .divide(subscription.termLength)
                              .format(true)}
                          </strong>
                          /month
                        </span>
                      </SettingsItem>
                      {isActive &&
                        !!currentPeriodEndsAt &&
                        !scheduledSeatsRemoval && (
                          <SettingsItem
                            className={style.priceItem}
                            label={
                              subscription.inTrial
                                ? "First payment"
                                : "Next payment"
                            }
                            responsive
                          >
                            <Flex column grow>
                              {showNextBill && (
                                <div>
                                  <strong>
                                    {currency(
                                      subscription.nextPaymentAmountInCents
                                    )
                                      .divide(100)
                                      .format(true)}
                                  </strong>
                                </div>
                              )}
                              <div className={style.nextPayment}>
                                <time>
                                  {formatDate(currentPeriodEndsAt)} (
                                  {fromNow(currentPeriodEndsAt)})
                                </time>
                              </div>
                            </Flex>
                          </SettingsItem>
                        )}
                      {isCanceled && !!currentPeriodEndsAt && (
                        <SettingsItem
                          className={style.priceItem}
                          label="Subscription ends"
                        >
                          <time>
                            {formatDate(currentPeriodEndsAt)} (
                            {fromNow(currentPeriodEndsAt)})
                          </time>
                        </SettingsItem>
                      )}

                      {scheduledSeatsRemoval && !!currentPeriodEndsAt && (
                        <React.Fragment>
                          <DialogForm
                            dangerous
                            isOpen={this.state.dialog === "cancelDowngrade"}
                            onRequestClose={this.hideDialog}
                            onClose={this.hideDialog}
                            title="Confirm"
                            onSubmit={this.resetPendingDowngrade}
                            primaryButton="Confirm"
                            secondaryButton="Cancel"
                          >
                            <p>
                              Are you sure you want to cancel this pending plan
                              change?
                            </p>
                          </DialogForm>
                          <SettingsItem
                            responsive
                            className={style.scheduledChangesItem}
                          >
                            <Theme.Consumer>
                              {({ themeName }) => (
                                <FormNotice
                                  icon="clock"
                                  iconWrapperClass={
                                    style.pendingSeatsIconWrapper
                                  }
                                  iconFillColor={
                                    themeName === "dark" ? "#FFFFFF" : "#C85100"
                                  }
                                  className={style.pendingSeatsNote}
                                  heading="You have subscription downgrade scheduled"
                                  body={
                                    <div>
                                      <p>
                                        Your downgrade to{" "}
                                        {this.subscriptionName(subscription)}
                                        {" with "}
                                        <strong>
                                          {subscription.totalSeatsPending}
                                        </strong>
                                        {" contributor "}
                                        {pluralize(
                                          "seats",
                                          subscription.totalSeatsPending
                                        )}
                                        {" will be applied on "}
                                        <time>
                                          {formatDate(currentPeriodEndsAt)}
                                        </time>
                                      </p>
                                      <p>
                                        The new price will go down from{" "}
                                        <strong>
                                          {currency(
                                            subscription.totalAmountInCents
                                          )
                                            .divide(100)
                                            .format(true)}
                                        </strong>
                                        {" to "}
                                        <strong>
                                          {currency(
                                            subscription.nextPaymentAmountInCents
                                          )
                                            .divide(100)
                                            .format(true)}
                                        </strong>
                                        /
                                        {subscription.termLength === 12
                                          ? "year. "
                                          : "month. "}
                                        <ButtonLink
                                          onClick={this.showCancelDowgrade}
                                        >
                                          Cancel downgrade
                                        </ButtonLink>
                                      </p>
                                    </div>
                                  }
                                />
                              )}
                            </Theme.Consumer>
                          </SettingsItem>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </FormSection>
                {isPremium && !manualCollection && (
                  <FormSection
                    heading={
                      <span className={style.detailsHeading}>
                        Payment Details
                      </span>
                    }
                    action={
                      hasPaymentDetails ? (
                        <span className={style.updateButtons}>
                          <Button onClick={this.showUpdateCompanyDetails}>
                            Update Company Details
                          </Button>
                          <Button onClick={this.showUpdatePaymentDetails}>
                            Update Payment Method
                          </Button>
                        </span>
                      ) : undefined
                    }
                  >
                    {hasPaymentDetails ? (
                      <PaymentDetails subscription={subscription} />
                    ) : (
                      <SettingsItem label="Credit card">
                        No card on file
                      </SettingsItem>
                    )}
                  </FormSection>
                )}
                {manualCollection && isPremium && (
                  <FormSection heading="Payment Details">
                    <SettingsItem label="Invoicing type">
                      <span>Manual</span>
                      {hasPaymentDetails && (
                        <Popover
                          placement="top"
                          label={`
                              Your payment method on file will be automatically
                              billed going forward. You will need to contact
                              support to switch back to manual invoicing.
                            `}
                        >
                          <Button
                            onClick={this.handleAutomaticInvoicing}
                            disabled={isFetching}
                            icon={isFetching ? "spinner" : undefined}
                          >
                            Switch to automatic invoicing
                          </Button>
                        </Popover>
                      )}
                    </SettingsItem>
                    <SettingsItem label="Invoices">
                      <span>
                        <a
                          className={style.invoiceLink}
                          href={recurlyAccountManagement(
                            subscription.recurlyLoginToken
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View and pay invoices
                          <Icon type="external" tint />
                        </a>
                        <Note>
                          Clicking this link will take you to our hosted
                          payments page where you can view, pay, and download
                          your invoices.
                        </Note>
                      </span>
                    </SettingsItem>
                  </FormSection>
                )}
              </div>
            )}
            <FormSection heading="Billing History">
              {!!invoices.length &&
                invoices.map((invoice) => (
                  <InvoiceItem key={invoice.createdAt} invoice={invoice} />
                ))}
              {!invoices.length && (
                <SettingsItem>
                  <Note className={style.noInvoices}>
                    There are currently no invoices available.
                  </Note>
                </SettingsItem>
              )}
            </FormSection>
            {isActive && this.props.areCancelAndDeleteButtonsVisible && (
              <FormSection heading="Cancel Subscription">
                <SettingsItem
                  responsive
                  label={
                    <Button
                      onClick={this.showConfirmCancelSubscription}
                      disabled={isFetching}
                      danger
                    >
                      Cancel Subscription
                    </Button>
                  }
                >
                  <Note className={style.cancelNote}>
                    You can cancel your subscription anytime. Your account
                    becomes read-only at the end of your billing cycle, but you
                    will still be able to export files. If you have any
                    questions,{" "}
                    <ButtonLink onClick={this.props.openSupportTicket}>
                      contact us
                    </ButtonLink>{" "}
                    — {`we’re here to help.`}
                  </Note>
                </SettingsItem>
              </FormSection>
            )}
            <SalesRequested
              isOpen={this.state.isSalesRequestedDialogOpen}
              onRequestClose={() => this.toggleSalesRequestedDialog(false)}
            />
          </div>
        )}
      </SettingsForm>
    );
  }
}

export default connector(OrganizationSettingsSubscription);
