// @flow
import { map } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { fetchActivities } from "core/actions/activities";
import { showToast } from "core/actions/toasts";
import { getFilteredActivityIds } from "core/selectors/activities";
import type { State, Dispatch } from "core/types";

export type OwnProps = {|
  organizationId: string,
  children: ({
    activityIds: string[],
    isLoading: boolean,
    isLoadingMore: boolean,
    onLoadMore: () => void,
    isOffline: boolean,
    isReloading: boolean,
  }) => React.Node,
  projectId?: string,
  branchId?: string,
  sha?: string,
  pageSize?: number,
|};

export type StateProps = {|
  initialActivityIds: string[],
  isOffline: boolean,
|};

export type DispatchProps = {|
  showError: (string) => void,
  loadActivities: Function,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type ComponentState = {
  hasMore: boolean,
  isLoading: boolean,
  isLoadingMore: boolean,
  isReloading: boolean,
  activityIds: string[],
};

class ActivitiesLoader extends React.Component<Props, ComponentState> {
  state = {
    total: 0,
    hasMore: true,
    isLoading: false,
    isLoadingMore: false,
    isReloading: true,
    activityIds: this.props.initialActivityIds,
  };

  componentDidMount() {
    const { organizationId, projectId, branchId } = this.props;
    const { activityIds } = this.state;

    this.loadInitial(
      { organizationId, projectId, branchId },
      {
        // Silently reload if we already have activityIds
        reload: activityIds && activityIds.length > 0,
      }
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { organizationId, projectId, branchId, sha } = prevProps;
    if (
      this.props.organizationId !== organizationId ||
      this.props.projectId !== projectId ||
      this.props.branchId !== branchId ||
      this.props.sha !== sha
    ) {
      this.loadInitial({
        organizationId: this.props.organizationId,
        projectId: this.props.projectId,
        branchId: this.props.branchId,
      });
    }
  }

  loadActivities = async (params: {
    organizationId: string,
    projectId?: string,
    branchId?: string,
    offset?: number,
  }) => {
    this.setState({ isLoadingMore: true });

    try {
      const response = await this.props.loadActivities(params);

      if (response) {
        const activityIds = this.state.activityIds.concat(
          map(response.data.activities, "id")
        );

        const hasMore = response.data.activities.length >= response.meta.limit;
        this.setState({ activityIds, hasMore });
      }
    } finally {
      this.setState({ isLoadingMore: false });
    }
  };

  loadInitial = async (
    params: {
      organizationId: string,
      projectId?: string,
      branchId?: string,
    },
    options?: { reload?: boolean } = {}
  ) => {
    this.setState({
      isLoading: !options.reload,
      isReloading: options.reload,
    });

    await this.loadActivities({ ...params, offset: 0 });

    this.setState({ isLoading: false, isReloading: false });
  };

  handleLoadMore = () => {
    if (this.props.isOffline) {
      return;
    }
    if (this.state.isLoadingMore) {
      return;
    }
    if (!this.state.hasMore) {
      return;
    }

    const { activityIds } = this.state;
    const { organizationId, projectId, branchId } = this.props;

    this.loadActivities({
      organizationId,
      projectId,
      branchId,
      offset: activityIds.length,
    });
  };

  render() {
    const { isLoading, isLoadingMore, isReloading, activityIds } = this.state;
    return this.props.children({
      activityIds,
      isLoading,
      isLoadingMore,
      onLoadMore: this.handleLoadMore,
      isOffline: this.props.isOffline,
      isReloading,
    });
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const isOffline = !isOnline(state);
  const { branchId, projectId, organizationId = "" } = props;

  return {
    initialActivityIds: getFilteredActivityIds(state, {
      organizationId,
      projectId,
      branchId,
    }),
    isOffline,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { pageSize = 25 } = props;

  return {
    showError: (text: string) => dispatch(showToast({ text })),
    loadActivities: ({
      organizationId,
      projectId,
      branchId,
      offset,
    }: {
      organizationId: string,
      projectId?: string,
      branchId?: string,
      offset: number,
    }) => {
      return dispatch(
        fetchActivities({
          organizationId,
          projectId,
          branchId,
          offset,
          limit: pageSize,
        })
      );
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesLoader);
