// @flow
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import defineRequest from "./";

type Params = {
  organizationId?: string,
  projectId?: string,
  branchId?: string,
  offset?: number,
  limit?: number,
};

export const ActivitiesFetchRequest = defineRequest<Params, Params>({
  id: (params: Params) => {
    return `get:activities?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    // 34 removes pagination total from the response meta
    const API_VERSION = 34;
    return apiRequest("get", `activities`, params, API_VERSION);
  },
});
