// @flow
import classnames from "classnames";
import * as React from "react";
import AddItemsToCollectionButton from "core/components/AddItemsToCollectionButton";
import Button from "core/components/Button";
import CollectionMenu from "core/components/CollectionMenu";
import CopyLinkButton from "core/components/CopyLinkButton";
import OnboardingBubble from "core/components/OnboardingBubble";
import Separator from "core/components/Separator";
import ShareButton from "core/components/ShareButton";
import type { Branch, Collection, ShareLink } from "core/types";
import style from "./style.scss";

type Props = {
  isMobile: boolean,
  isDocked: boolean,
  isPublic: boolean,
  organizationId: string,
  projectId: string,
  canEdit: boolean,
  canPresent?: boolean,
  showMore: boolean,
  onGoBack: () => void,
  onPresent: () => void,
  collection: ?Collection,
  branch: ?Branch,
  shareLink?: ShareLink,
  handleAddDraftCollectionText: () => void,
};

export default function TopBar(props: Props) {
  const {
    isMobile,
    isDocked,
    isPublic,
    organizationId,
    projectId,
    canEdit,
    canPresent,
    showMore,
    onGoBack,
    onPresent,
    collection,
    branch,
    shareLink,
  } = props;

  if (!collection || !branch) {
    return <div className={style.topBarHeight} />;
  }

  const inputShare = {
    kind: "collection",
    descriptor: {
      projectId: projectId,
      branchId: branch.id,
      collectionId: collection.id,
    },
  };

  const shareProps = {
    nude: false,
    tint: false,
    hideLabel: isMobile,
    organizationId,
  };

  const showCopyLinkButton = shareLink && isPublic;
  const showEditActions = canEdit && !isPublic;

  return (
    <div
      className={classnames(style.topBar, style.topBarHeight, {
        [style.public]: isPublic,
        [style.docked]: isDocked,
      })}
    >
      <div className={style.topBarLeft}>
        {!isPublic && (
          <Button
            nude
            tint
            icon="close"
            onClick={onGoBack}
            qaSelector="close-collection-button"
          />
        )}
      </div>
      <div
        className={classnames(style.topBarMiddle, {
          [style.hidden]: isDocked,
        })}
      >
        {collection.name}
      </div>
      <div className={style.topBarRight}>
        {showCopyLinkButton && (
          <div className={style.action}>
            <CopyLinkButton
              {...shareProps}
              icon="link"
              inputShare={inputShare}
              url={shareLink && shareLink.url}
              doNotCreateShareLink
            >
              Copy Link
            </CopyLinkButton>
          </div>
        )}
        {showEditActions && (
          <React.Fragment>
            <div className={style.action}>
              <OnboardingBubble
                storageKey="collection-section-onboarded"
                text="Provide context and organize your Collections by adding text sections."
              >
                <Button
                  icon="text-box"
                  onClick={props.handleAddDraftCollectionText}
                  title="Add text section"
                  tooltip={{ placement: "bottom" }}
                  qaSelector="add-text-section-button"
                />
              </OnboardingBubble>
            </div>
            <div className={style.action}>
              <AddItemsToCollectionButton
                mobile={isMobile}
                collection={collection}
                branch={branch}
                qaSelector="add-items-button"
              />
            </div>
          </React.Fragment>
        )}
        {(showCopyLinkButton || showEditActions) && (
          <Separator className={style.separator} />
        )}
        <Button
          primary={isPublic}
          hideLabel={isMobile}
          icon={isPublic ? "play" : "play-tint"}
          onClick={onPresent}
          disabled={!canPresent}
          qaSelector="presentButton"
        >
          Present
        </Button>
        {!!collection.publishedAt && !isPublic && (
          <div className={style.action}>
            <ShareButton isMobile={isMobile} inputShare={inputShare} />
          </div>
        )}
        {showMore && !isPublic && (
          <div className={style.action}>
            <CollectionMenu collection={collection}>
              {(showMenu, menuRef) => (
                <Button
                  nude
                  icon="overflow"
                  onClick={showMenu}
                  innerRef={menuRef}
                />
              )}
            </CollectionMenu>
          </div>
        )}
      </div>
    </div>
  );
}
